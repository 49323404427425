import React, { Component } from "react";
import Settings_Receiver from "../../contexts/settings_context/Settings_Receiver";

import {
  Segment,
  Grid,
  Checkbox,
  Header,
  Form,
  Popup,
  Icon,
  Divider,
} from "semantic-ui-react";
import SettingsList from "../common/lists/settingList";
import api from "../../actions/api";
import RotaTypesWithRoles from "../common/lists/incidentTypeModules";
import Box from "../common/box";
import ColourTypeList from "../common/lists/colourTypeList";

class StaffSettings extends Component {
  state = {
    data: {},
  };
  updateFormTimeout = 0;

  componentDidMount() {
    if (this.props.settings) {
      this.setState({
        data: this.props.settings,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings !== prevProps.settings) {
      this.setState({
        data: this.props.settings,
      });
    }
  }
  handleCheckboxChange = (props, e) => {
    this.setState(
      {
        updating: true,
        data: { ...this.state.data, [e.name]: e.checked },
      },
      () => {
        if (this.updateFormTimeout) {
          clearTimeout(this.updateFormTimeout);
        }
        this.updateFormTimeout = setTimeout(() => {
          return this.handleSubmit(this.state.data)
            .then((res) => {
              this.setState({
                updating: false,
              });
              //TODO add a fancy screen notification that the title has successfully updated
            })
            .catch((err) => {
              console.error("Unable to save", err);
            });
        }, 500);
      }
    );
  };
  handleSubmit = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  handleDelete = (data) => {
    return api.settings.update(data).then(() => {
      this.props.settings._refresh();
    });
  };
  render() {
    let orgRoles = [];
    let rotaTypes = [];
    let responderStatus = [];

    if (this.props.settings.orgRoles) {
      orgRoles = this.props.settings.orgRoles;
    }
    if (this.props.settings.rotaTypes) {
      rotaTypes = this.props.settings.rotaTypes;
    }
    if (this.props.settings.responderStatus) {
      responderStatus = this.props.settings.responderStatus;
    }

    return (
      <Segment basic>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic className="no-pad">
                <SettingsList
                  submit={this.handleSubmit}
                  delete={this.handleDelete}
                  data={orgRoles}
                  placeholder="Paramedic, Traffic Officer..."
                  title="Org Roles"
                  field="orgRoles"
                />
                <Divider hidden />
                <ColourTypeList
                  submit={this.handleSubmit}
                  delete={this.handleDelete}
                  data={responderStatus}
                  placeholder="On way, State 6...."
                  title="Responder Status"
                  field="responderStatus"
                />
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Box title={"Setting Flags"}>
                <Segment basic textAlign="center">
                  <Form>
                    <Popup
                      basic
                      content={"Does your team use rotas?"}
                      position={"top center"}
                      trigger={
                        <Header className={"withIcon"}>
                          Use Rotas
                          <Icon
                            name="info circle"
                            color="yellow"
                            size="small"
                          />
                        </Header>
                      }
                    />
                    <Checkbox
                      toggle
                      name="hasRotas"
                      className={"goodToggle"}
                      checked={this.state.data.hasRotas}
                      onChange={this.handleCheckboxChange}
                    />
                  </Form>
                </Segment>
                {/* <RotaTypesWithRoles
                 submit={this.handleSubmit}
                 delete={this.handleDelete}
                 data={rotaTypes}
                 roles={orgRoles}
                 placeholder="Controller, Planner....."
                 title="Rota Types"
                 field="rotaTypes"
                 disabled={this.state.data.hasRotas === false ? true : false}
                 /> */}
                <Segment basic textAlign="center">
                  <Form>
                    <Popup
                      basic
                      content={"Do you want to show non-operational team members when assigng jobs?"}
                      position={"top center"}
                      trigger={
                        <Header className={"withIcon"}>
                          Show Non-Op
                          <Icon
                            name="info circle"
                            color="yellow"
                            size="small"
                          />
                        </Header>
                      }
                    />
                    <Checkbox
                      toggle
                      name="showNonOp"
                      className={"goodToggle"}
                      checked={this.state.data.showNonOp}
                      onChange={this.handleCheckboxChange}
                    />
                  </Form>
                </Segment>
                {/* <RotaTypesWithRoles
                 submit={this.handleSubmit}
                 delete={this.handleDelete}
                 data={rotaTypes}
                 roles={orgRoles}
                 placeholder="Controller, Planner....."
                 title="Rota Types"
                 field="rotaTypes"
                 disabled={this.state.data.hasRotas === false ? true : false}
                 /> */}
              </Box>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Settings_Receiver(StaffSettings, (settings) => {
  return {
    settings,
  };
});
