import React from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  Icon,
  Dropdown,
  Header,
  Popup,
  Responsive,
  Segment,
} from "semantic-ui-react";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { FaLink } from "react-icons/fa";
import BuildNumber from "../../_buildNumber";
import { VersionNumber } from "../../_changeLog";
import { VideoCameraIcon } from "@heroicons/react/solid";

export default class FooterNav extends React.Component {
  state = {
    user: {},
    name: "--",
  };
  componentDidMount() {
    let user = Authentication.getUserData();
    if (user) {
      this.setState({
        user,
      });
    }
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleLogout = () => {
    Authentication.logout();
  };
  isAdmin = () => {
    let admin = false;
    let user = Authentication.getUserData();
    if (user) {
      if (user.groups && user.groups.length) {
        user.groups.forEach((group) => {
          // Global Admins - Org Admins - Full Access
          if (
            group === "5c5af952d6c1ed0e2c83e1fb" || // Sarstuff Admin
            group === "5cfa6289cce9f7496099bf28" || // Organisation Admins
            group === "5cf671b0b2aca6713677a2f2" // SarSYS - Full Access
          ) {
            admin = true;
          }
        });
      }
    }
    return admin;
  };
  render() {
    const { activeItem } = this.state;

    return (
      <React.Fragment>
        <Responsive maxWidth={768} as={Segment} basic className="no-pad">
          <Menu className={"footer-menu"} stackable>
            <Dropdown
              text={Authentication.getUserData().name}
              pointing
              className="link item"
              upward
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleLogout()}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {this.isAdmin() && (
              <Popup
                trigger={
                  <Menu.Item
                    name={"settings"}
                    as={Link}
                    to="/settings"
                    color="yellow"
                    active={activeItem === "settings"}
                    onClick={this.handleItemClick}
                  >
                    <Icon name="setting" color="yellow" />
                  </Menu.Item>
                }
                content="System Settings"
              />
            )}
          </Menu>
        </Responsive>
        <Responsive minWidth={768} as={Segment} basic>
          <Menu fixed={"bottom"} className={"footer-menu"} stackable>
            <Dropdown
              text={Authentication.getUserData().name}
              pointing
              className="link item"
              upward
            >
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/staff/your-details`}>
                  Your Details
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleLogout()}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {this.isAdmin() && (
              <React.Fragment>
                <Popup
                  trigger={
                    <Menu.Item
                      name={"settings"}
                      as={Link}
                      to="/settings"
                      color="yellow"
                      active={activeItem === "settings"}
                      onClick={this.handleItemClick}
                    >
                      <Icon name="setting" color="yellow" />
                    </Menu.Item>
                  }
                  content="System Settings"
                />
                <Menu.Item
                  name={"staff"}
                  as={Link}
                  to="/staff"
                  active={activeItem === "staff"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="users" />
                  Staff
                </Menu.Item>
                <Menu.Item
                  name={"predefined"}
                  as={Link}
                  to="/predefined"
                  active={activeItem === "predefined"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="building" />
                  Predefined
                </Menu.Item>
              </React.Fragment>
            )}
            <Dropdown text={"Archive"} pointing className="link item" upward>
              <Dropdown.Menu>
                <Dropdown.Header>Archived</Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to={"/archive/log"}>
                  Log
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/archive/jobs"}>
                  Jobs
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/archive/clients"}>
                  Clients
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/archive/locations"}>
                  Locations
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item
              name={"links"}
              as={Link}
              to="/links"
              active={activeItem === "links"}
              onClick={this.handleItemClick}
            >
              <FaLink size={"1.4rem"} />
              Links
            </Menu.Item>
            <Menu.Item
              name={"live-feeds"}
              as={Link}
              to={"/live-feeds"}
              active={activeItem === "live-feeds"}
              icon
              onClick={this.handleItemClick}
            >
              <VideoCameraIcon className="h-6" />
              Feeds
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Header as={"h4"} className={"subtle"}>
                  {this.state.user && this.state.user.organisation
                    ? this.state.user.organisation.name
                    : "--"}
                </Header>
              </Menu.Item>
              <Menu.Item>
                <Header as={"h4"} className={"subtle"}>
                  V{VersionNumber}.{BuildNumber}
                </Header>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Responsive>
      </React.Fragment>
    );
  }
}
